<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">Human Resources</a>
                </li>
                <li class="breadcrumb-item text-capitalize active" aria-current="page">Attendance</li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Manage Attendance</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-6 tx-right">
        <button
          v-if="checkSinglePermission('create_attendance')"
          type="button"
          class="btn btn-primary mg-t-8 mg-r-0 mg-b-0"
          @click="$router.push('attendance-create')"
        >New Attendance</button>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head pt-0">
      <div class="row">
        <div class="filter-input col-md-9 d-block mr-0 pr-0">
          <select name="size" v-model="params.pageIndex" @change="searchFilter()" class="mr-2 mg-t-5">
            <option value="15">15</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
          <button
            type="button"
            class="btn btn-xs btn-primary mg-b-0 mr-2"
            @click="$router.push('attendance-day')"
          >Day Report</button>
          <button
            type="button"
            class="btn btn-xs btn-primary mg-b-0 mr-2"
            @click="$router.push('attendance-month')"
          >Month Report</button>
          <select v-model="params.branch" class="filter-input mr-2 mg-t-5" @change="searchFilter()">
            <option value="">All Branch</option>
            <option
              v-for="(branch, index) in dataLists2.filter_branchs"
              :key="index"
              :value="branch.id"
            >{{ branch.name }}</option>
          </select>
          <input
            type="date"
            class="filter-input mr-2 mg-t-5"
            @change="searchFilter()"
            v-model="params.date"
          />
        </div>
        <div class="col-md-3 ml-0 pl-0 mg-t-5">
          <input
            type="text"
            class="float-right"
            placeholder="Search..."
            v-model="params.searched"
            @keyup="searchKeyword"
          />
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12">
        <table class="table table2 table-hover" id="exampleTable">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N.</th>
              <th class="wd-5p">Emp. ID</th>
              <th class="wd-15p">Employee Name</th>
              <th class="wd-10p">Designaton</th>
              <th class="wd-15p">Status</th>
              <th class="wd-10p">Work Time</th>
              <th class="wd-10p">In-Time</th>
              <th class="wd-10p">Out-Time</th>
              <th class="wd-20p table-end-item">Remark</th>
            </tr>
          </thead>
          <tbody v-if="!loading && dataLists.length > 0">
            <tr v-for="(attendence, index) in dataLists" :key="index">
              <th scope="row" class="table-start-item">{{index+pageSerialNo}}.</th>
              <td>{{attendence.employee.employee_id}}</td>
              <td>{{attendence.employee.name}}</td>
              <td>{{attendence.employee.designation}}</td>
              <td
                v-if="attendence.attendence_status !== null && attendence.attendence_status !== ''"
              >{{attendence.attendence_status}}</td>
              <td v-else>- - -</td>
              <td
                v-if="attendence.work_time !== null && attendence.work_time !== '' && attendence.attendence_status!=null && attendence.attendence_status == 'present'"
              >{{attendence.work_time}}</td>
              <td v-else>- - -</td>
              <td
                v-if="attendence.in_time !== null && attendence.in_time !== '' && attendence.attendence_status!=null && attendence.attendence_status =='present'"
              >{{attendence.in_time}}</td>
              <td v-else>- - -</td>
              <td
                v-if="attendence.out_time !== null && attendence.out_time !== '' && attendence.attendence_status!=null && attendence.attendence_status =='present'"
              >{{attendence.out_time}}</td>
              <td v-else>---</td>
              <td
                v-if="attendence.remark !== null && attendence.remark !== ''"
              >{{attendence.remark}}</td>
              <td v-else>---</td>
            </tr>
          </tbody>
          <tbody v-else-if="error"><tr><td colspan="11" class="text-center">{{error_message}}</td></tr></tbody>
          <tbody v-else-if="!loading && dataLists.length == 0">
            <tr>
              <td colspan="11" style="text-align:center;">No records found.</td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td colspan="11" style="text-align:center;">Loading.....</td>
            </tr>
          </tbody>
          
        </table>
      </div>
    </div>
      <Paginate
        v-model="page"
        :pageCount="totalPageCount"
        :containerClass="'pagination'"
        :clickHandler="clickCallback"
        v-if="totalPageCount > 1"
      />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Services from "../Services/Services";
import Paginate from "vuejs-paginate";
import _ from "lodash";
export default {
  components: {
    Paginate,
  },
  data() {
    return {
      date: "",
      loading: false,
      error: false,
      page: 1,
      params: {
        pageIndex: 15,
        branch: "",
        date: new Date().toJSON().slice(0, 10).replace(/-/g, "-"),
        searched: "",
        offset: 0,
      },
      totalPageCount: 0,
      error_message:'',
      pageSerialNo: 1,
    };
  },
  computed: {
    ...mapGetters([
      "processing",
      "eventMessage",
      "dataLists",
      "dataLists2",
      "dataId",
      "modalId",
      "modalMode",
      "today",
    ]),
  },
  methods: {
     clickCallback: function(page) {
      if(page == 1){
        this.pageSerialNo = 1;
      }else{
        this.pageSerialNo = ((page-1) * this.params.pageIndex) + 1 ;
      }
      this.params.offset = (page-1) * this.params.pageIndex;
      this.getData();
    },
    getData() {
      this.loading = true;
      this.error = false;
      Services.getAttendenceDayReport(this.params)
        .then((res) => {
          this.$store.commit("setDataLists", res.data.data.data);
          this.totalPageCount = res.data.data.total_no_of_pages;
        })
        .catch((err) => {
          this.error = true;
           if(!Object.prototype.hasOwnProperty.call(err,"response.data.error_message")) {
            if (err.response.data.error_message == "Access Denied.") {
              this.error_message = "Access Denied !!!";
              } else {
                this.error_message = "Error fetching data from the server.";
              }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    searchKeyword: _.debounce(function () {
      this.params.offset = 0;
      this.pageSerialNo = 1;
      this.page = 1;
      this.getData();
    }, 1000),
    searchFilter() {
      this.params.offset = 0;
      this.pageSerialNo = 1;
      this.page = 1;
      this.getData();
    },
  },
  mounted() {
    this.$store.commit("getData2", `api/employee/create`);
    this.getData();
  },
};
</script>
<style scoped>
</style>